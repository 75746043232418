import React from 'react';

const ManageOwnPower = () => {
  return (
    <div className='energe energe-power'>
      <h3 className='energe-power-title'>Manage My Power</h3>
      <div className='energe-power-content'> </div>
      <div className='energe-power-image'></div>
    </div>
  );
};

export default ManageOwnPower;
